(function ($) {
  Drupal.behaviors.productShadePickerV1 = {
    attach: function (context) {
      var _activateShade = function ($skuListItems, skuBaseId) {
        if ($skuListItems.length > 0 && !!skuBaseId) {
          $skuListItems
            .removeClass('active')
            .filter('[data-sku=' + skuBaseId + ']')
            .addClass('active');
        }
      };

      $('.js-product-shade-picker', context).each(function () {
        var $template = $(this);
        var $skuListItems = $template.find('.js-product-shade');
        var skuBaseId = $skuListItems.data('sku');

        _activateShade($skuListItems, skuBaseId);
      });
    }
  };
  function _setSmooshColor(skuId, $product) {
    var $smoosh = $('.js-product-smoosh', $product);
    var sku;

    if (skuId) {
      sku = prodcat.data.getSku(skuId);
      if (sku && sku.HEX_VALUE_STRING) {
        $smoosh.css('background-color', sku.HEX_VALUE_STRING);
      }
    }
  }

  $(document).on('click', '.js-product-shade', function (event) {
    event.preventDefault();
    var skuId = $(this).data('sku');
    var title = $(this).attr('title');

    if (skuId) {
      var sku = prodcat.data.getSku(skuId);
      var skuBaseId;

      if (sku) {
        skuBaseId = sku.SKU_BASE_ID;
        var $product = $(this).closest('.js-product');
        var $prodImgMobile = $('.js-spp-link img.mobile-only', $product);
        var $prodImgPC = $('.js-spp-link img.pc-only', $product);

        $(document).trigger('product.updateRoute', ['shade', title]);
        // Toggle the active state of the selected shades
        $product.find('.js-product-shade').removeClass('active');
        $(this).addClass('active');
        if ($prodImgPC.eq(0) && sku.LARGE_IMAGE[0]) {
          $prodImgPC.eq(0).attr('src', sku.LARGE_IMAGE[0]);
        }
        if ($prodImgMobile && sku.IMAGE_MPP_L[0]) {
          $prodImgMobile.eq(0).attr('src', sku.IMAGE_MPP_L[0]);
        }
      }
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    $('.js-product-shade', this).removeClass('active');
    $('.js-product-shade[data-sku="SKU' + skuBaseId + '"]', this).addClass('active');
    _setSmooshColor(skuBaseId, $(this));
    $('select.js-sku-menu', this).val(skuBaseId);
  });

  $(document).on('mouseenter', '.js-product-shade', function () {
    var $product = $(this).closest('.js-product');
    var skuId = $(this).data('sku');

    _setSmooshColor(skuId, $product);
  });

  $(document).on('mouseleave', '.js-product-shade', function () {
    var $product = $(this).closest('.js-product');
    var skuId = $product.data('sku-base-id');

    _setSmooshColor(skuId, $product);
  });
})(jQuery);
